<template>
	<div id="list">
		<headers :whiteFlg="true" :activeItem="1" />
		<div class="have-top-nav"></div>
		<div class="container">
			<ul class="list">
				<li v-for="(LItem,Lindex) in recoveryProductList" :key="Lindex"
					@click="navToDetail(LItem.recoveryProductId)">
					<div class="pic" :style="{backgroundImage:'url('+LItem.recoveryProductImg+')'}"></div>
					<h2 class="ellipsis-2">{{LItem.recoveryProductDetail}}</h2>
					<div class="other">
						<div class="blk">
							<span>已有{{LItem.offerUserList.length}}位用户出价</span>
							<div class="price-people">
								<img :src="item1.isAnonymous==0? item1.userHeadimg:item1.offerAnonymousHeadimg"
									v-for="(item1,ind1) in LItem.offerUserList" :key="ind1" alt="">
							</div>
						</div>
						<div class="blk">
							<span>已有{{LItem.userList.length}}位商家估价</span>
							<div class="price-people">
								<img :src="item2.userHeadimg" v-for="(item2,ind2) in LItem.userList" :key="ind2" alt="">
							</div>
						</div>
					</div>
				</li>
			</ul>
			<div class="more" @click="popShowFlg = true">查看更多</div>

			<div class="pop-over-container pop-over" v-if="popShowFlg" @click="popShowFlg = false">
				<div class="download-app"><i class="sweet-potato"></i>
					<div class="image-box"><img src="https://xunzhong-1311798464.cos.ap-shanghai.myqcloud.com/upload/file/diuPc/xcx-.jpg">
					</div>
					<p>扫码进入么丢</p>
					<p class="plr20">与全世界的么友们一起进入低碳生活</p>
					<div class="closeTxt" @click="popShowFlg = false">关闭</div>
				</div>
			</div>
		</div>
		<footers :bottom="true" />
	</div>
</template>

<script>
	const axios = require('axios');
	import headers from "./components/headers.vue";
	import footers from "./components/footers.vue";
	export default {
		name: "list",
		components: {
			headers,
			footers
		},
		data() {
			return {
				popShowFlg: false,
				recoveryProductList: [],
			}
		},
		mounted() {
			this.requestData()
		},
		methods: {
			requestData() {
				axios.post('/appOfficialWebsite/appOfficialWebsiteProList', {})
					.then((res) => {
						this.recoveryProductList = res.data.data.recoveryProductList;
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			navToDetail(ids) {
				this.$router.push({
					path: './detail',
					query: {
						recoveryProductId: ids
					}
				})

			}
		}
	}
</script>

<style scoped>
	.list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 0.5rem 0;
	}

	.list li {
		width: 18.5%;
		margin: 1.5rem 0;
		position: relative;
	}

	.list li:hover {
		cursor: pointer;
	}

	.list li:hover:after {
		width: 100%;
		height: 100%;
		background-color: rgba(0, 2, 4, .2);
		position: absolute;
		top: 0;
		left: 0;
		border-radius: 8px;
		content: "";
	}

	.list .pic {
		overflow: hidden;
		border-radius: 0.5rem 0.5rem 0px 0px;
		width: 11rem;
		max-width: 100%;
		height: 12.25rem;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;

	}

	.list h2 {
		color: #333333;
		font-size: 0.8rem;
		margin-top: 0.75rem;
		padding: 0 0.75rem;
		height: 2.4rem;
		line-height: 1.2rem;
	}

	.list .other {
		padding: 0 0.75rem;
	}

	.list .other .blk {
		margin: 0.5rem 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.list .other span {
		color: #999999;
		font-size: 0.75rem;
	}

	.price-people {
		position: relative;
	}

	.price-people img {
		width: 1.3rem;
		height: 1.3rem;
		margin-left: -.5rem;
		border-radius: 100%;
		border: 1px solid #fff;
	}

	.more {
		text-align: center;
		cursor: pointer;
		width: 100%;
		height: 2rem;
		color: #1F4D0D;
		font-size: 0.8rem;
		line-height: 2rem;
		font-weight: 600;
	}

	.pop-over {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1;
	}

	.pop-over-container {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: rgba(0, 0, 0, .4);
	}

	.pop-over-container .download-app {
		width: 14rem;
		height: 13.55rem;
		background-color: #fff;
		border-radius: 0.4rem;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		position: relative;
	}

	.pop-over-container .download-app .image-box {
		width: 7rem;
		height: 7rem;
		border-radius: 0.25rem;
	}

	.pop-over-container .download-app .image-box img {
		width: 100%;
		height: 100%;
	}

	.pop-over-container .download-app p {
		font-size: 0.7rem;
		color: #555;
		text-align: center;
		margin: 0 0 0.15rem;
	}

	.pop-over-container .download-app .closeTxt {
		cursor: pointer;
		width: 100%;
		color: #1F4D0D;
		font-size: 0.7rem;
		font-weight: 600;
		text-align: center;
		border-top: 1px solid #eee;
		padding: 0.5rem 0;
		margin: 0.9rem 0 0;
	}
</style>
